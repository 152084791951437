import './index.scss'

$(document).scroll(function () {
    var scroH = $(document).scrollTop();
    var viewH = $(window).height();
    var indexTop = $('.index-cont1 .index-list').offset().top;
    if (scroH > indexTop - viewH / 2) {
        $('.index-cont1').addClass('bg2')
    } else {
        $('.index-cont1').removeClass('bg2')
    }

});